document.addEventListener("DOMContentLoaded", function (event) {
  // console.log("DOM loaded");
  //wait until images, links, fonts, stylesheets, and js is loaded
  window.addEventListener(
    "load",
    function (e) {
      //START FUNCTION

      gsap.registerPlugin(ScrollTrigger);

      let homeAnim1 = document.querySelector(".home-anim-1");
      if (homeAnim1) {
        // HOME ANIM
        var tl = gsap.timeline({});
        tl.fromTo(
          ".home-anim-1",
          {
            y: 1200,
            autoAlpha: 0,
          },
          {
            duration: 1.5,
            y: 0,
            autoAlpha: 1,
            ease: "expo.out",
          }
        );
        tl.fromTo(
          ".home-anim-2",
          {
            x: 400,
            autoAlpha: 0,
          },
          {
            duration: 1.2,
            x: 0,
            autoAlpha: 1,
            ease: "expo.out",
          },
          "<1"
        );
        tl.fromTo(
          ".home-anim-3",
          {
            y: -1000,
            x: 900,
            autoAlpha: 0,
          },
          {
            duration: 1.2,
            y: 0,
            x: 0,
            autoAlpha: 1,
            ease: "expo.out",
          },
          "<"
        );
      }

      // MOVE UP
      const moveUp = gsap.utils.toArray(".move-up");
      moveUp.forEach((moveUp) => {
        gsap.from(moveUp, {
          y: 250,
          scrollTrigger: {
            start: "top 100%",
            end: "top 90%",
            trigger: moveUp,
            // markers: true,
            scrub: 1,
          },
        });
      });

      // MARQUEE FX
      const marquees = gsap.utils.toArray(".marquee");
      marquees.forEach((marquee, index) => {
        const [x, xEnd] =
          index % 2
            ? [
                Math.floor(Math.random() * (15 - 12) + 12) * -1 + "%",
                Math.floor(Math.random() * (15 - 12) + 12) * 1 + "%",
              ]
            : [
                Math.floor(Math.random() * (15 - 12) + 12) * 1 + "%",
                Math.floor(Math.random() * (15 - 12) + 12) * -1 + "%",
              ];
        gsap.fromTo(
          marquee,
          { x },
          {
            x: xEnd,
            scrollTrigger: {
              trigger: marquee,
              scrub: 1,
              // markers: true,
              start: "top bottom",
              end: "bottom top",
              ease: "power2",
            },
          }
        );
      });

      // END FUNCTION
    },
    false
  );
});
