jQuery(function ($) {
  /***********************************************************************/
  // ADD CLASS TO TOP-NAV-COLLAPSE
  if ($("nav.navbar").length) {
    function collapseNavbar() {
      if ($("nav.navbar").offset().top > 50) {
        $("#wrapper-navbar nav.navbar-regular").addClass("active");
        $("#wrapper-navbar nav.navbar-blog").addClass("active");
      } else {
        $("#wrapper-navbar nav.navbar-blog").addClass("active");
        $("#wrapper-navbar nav.navbar-regular").removeClass("active");
      }
    }
    $(window).scroll(collapseNavbar);
    $(document).ready(collapseNavbar);
  }

  /***********************************************************************/
  // ADD CLASS TO OFFCANVAS FOR ANIMATIONS
  var menuOffcanvas = document.getElementById("navbarNavOffcanvas");
  if (menuOffcanvas) {
    menuOffcanvas.addEventListener("show.bs.offcanvas", function () {
      $(".navbar-toggler").addClass("toggled");
      $(".offcanvas").addClass("toggled");
    });
    menuOffcanvas.addEventListener("hidden.bs.offcanvas", function () {
      $(".offcanvas").removeClass("toggled");
    });
    menuOffcanvas.addEventListener("hide.bs.offcanvas", function () {
      $(".navbar-toggler").removeClass("toggled");
    });
  }
  var searchModal = document.getElementById("searchModal");
  if (searchModal) {
    searchModal.addEventListener("shown.bs.modal", function () {
      $("#searchInput").focus();
    });
  }

  /***********************************************************************/
  // HIDE HEADER ON SCROLL DOWN

  var prevScrollpos = window.pageYOffset;
  var headerDiv = document.getElementById("main-nav");
  var headerBlogDiv = document.getElementById("main-nav-blog");
  if (headerDiv) {
    var headerBottom = headerDiv.offsetTop + headerDiv.offsetHeight;
  }
  if (headerBlogDiv) {
    var headerBlogBottom = headerBlogDiv.offsetTop + headerBlogDiv.offsetHeight;
  }
  function blogHeader() {
    var currentScrollPos = window.pageYOffset;
    var window_width = $(window).width();
    if (window_width < 1200) {
      headerBlogDiv.style.top = "0";
      if (
        prevScrollpos > currentScrollPos ||
        currentScrollPos < headerBlogBottom
      ) {
        headerBlogDiv.style.top = "0";
      } else {
        headerBlogDiv.style.top = "-7rem";
      }
      prevScrollpos = currentScrollPos;
    } else {
      headerBlogDiv.style.top = "4rem";
      if (
        prevScrollpos > currentScrollPos ||
        currentScrollPos < headerBlogBottom
      ) {
        headerBlogDiv.style.top = "4rem";
      } else {
        headerBlogDiv.style.top = "-7rem";
      }
      prevScrollpos = currentScrollPos;
    }
  }
  function siteHeader() {
    var currentScrollPos = window.pageYOffset;
    var window_width = $(window).width();
    if (window_width < 1200) {
      if (prevScrollpos > currentScrollPos || currentScrollPos < headerBottom) {
        headerDiv.style.top = "0";
      } else {
        headerDiv.style.top = "-7rem";
      }
      prevScrollpos = currentScrollPos;
    } else {
      headerDiv.style.top = "0";
      prevScrollpos = currentScrollPos;
    }
  }
  if ($(headerBlogDiv).length) {
    $(window).scroll(blogHeader);
    $(window).resize(blogHeader);
    $(document).ready(blogHeader);
  }
  if ($(headerDiv).length) {
    $(window).scroll(siteHeader);
    $(window).resize(siteHeader);
    $(document).ready(siteHeader);
  }

  /***********************************************************************/
  // ANCHOR NAVIGATION
  function anchor_nav() {
    $(".anchor-btn").click(function (event) {
      var sectionTo = $(this).attr("href");
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop:
              $(sectionTo).offset().top +
              $(sectionTo).outerHeight(true) -
              $(sectionTo).outerHeight(true) +
              10,
          },
          700,
          "easeInOutExpo"
        );
      window.location.hash = "";
      event.preventDefault();
      event.stopPropagation();
    });
  }
  anchor_nav();

  /***********************************************************************/
  //SLICK FUNDOS MODAL
  $("#fundos-video").on("hide.bs.modal", function () {
    $("#fundos-video iframe").attr("src", "");
  });

  const exampleModal = document.getElementById("fundos-video");
  if (exampleModal) {
    exampleModal.addEventListener("show.bs.modal", (event) => {
      const button = event.relatedTarget;
      const recipient = button.getAttribute("data-bs-id");
      const modalId = exampleModal.querySelector("#linkIdModal");
      modalId.setAttribute("src", recipient + "?autoplay=1");
    });
  }
  /***********************************************************************/
  // DROPDOWN TRANSITION

  $("nav.navbar .dropdown").hover(function () {
    $(".dropdown-menu", this).css("transition", "all 0.2s ease-in-out");
  });

  /***********************************************************************/
  // MARQUEE REPEATED TEXT
  $(".marquee .repeat").each(function () {
    var $this = $(this);
    var text = $this.text();
    text += "&nbsp;";
    $this.html($this.text());
    var width = $this.outerWidth();
    var parentWidth = $this.parent("div").outerWidth();
    var numReps = Math.floor(parentWidth / width);
    $this.html(text.repeat(numReps));
  });

  /***********************************************************************/
  // DOC TYPE FILTER AJAX
  $("#filter").submit(function () {
    var filter = $("#filter");
    $.ajax({
      url: filter.attr("action"),
      data: filter.serialize(), // form data
      type: filter.attr("method"), // POST
      beforeSend: function (xhr) {
        filter.find("button").text("Filtrando..."); // changing the button label
      },
      success: function (data) {
        filter.find("button").text("Filtrar documentos"); // changing the button label back
        $("#response").html(data); // insert data
      },
    });
    return false;
  });

  $(".glink").click(function(event) {
    event.preventDefault();
  });

  // END $ FUNCTION
});
